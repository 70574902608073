<template>
    <div class="modal" :class="{'is-active': active}">
        <div class = "modal-background"  @click="$emit('close')"></div>
        <div class="modal-content">
            <div class="box">
                <div class="field">
                    <label class="label">
                        Username
                    </label>
                    <input class="input" type="text" v-model="username">
                </div>
                <div class="field">
                    <label class="label">
                        Password
                    </label>
                    <input class="input" type="text" v-model="password">
                </div>
                <div class="field">
                    <button class="button" @click="logIn">Log In</button>
                </div>
            </div>
        </div>
        <button class="modal-close" @click="$emit('close')"></button>
    </div>
</template>

<script>
import { toast } from 'bulma-toast'

export default {
    name:'LocalLoginModal',
    props:{
        localUsers: Object,
        active: Boolean
    },
    emits: ['localLogIn','createLocalUser','close'],
    data(){
        return{
            username: '',
            password: '',
        }
    },
    methods:{
        logIn(){
            if( (this.username + '_l') in this.localUsers){
                if(this.localUsers[this.username + '_l'] === this.password){
                    this.$emit('localLogIn',this.username + '_l',this.password)
                }else{
                    toast({
                        message: 'Password incorrect or User already exists',
                        type: 'is-danger',
                        dismissible: true,
                        position: 'center'
                    })
                    return
                }
            }else{
                this.$emit('createLocalUser',this.username + '_l',this.password)
            }
            this.$emit('close')
        },
    },
}
</script>
