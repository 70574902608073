import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

import Poll from '../views/Poll.vue'
import Polls from '../views/Polls.vue'
import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import MyAccount from '../views/MyAccount.vue'
import Schedule from '../views/Schedule.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/sign-up/',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/log-in/',
    name: 'LogIn',
    component: LogIn,
  },
  {
    path: '/my-account/',
    name: 'MyAccount',
    component: MyAccount,
  },
  {
    path: '/polls/:poll_slug/',
    name: 'Poll',
    component: Poll,
  },
  {
    path: '/polls/',
    name: 'Polls',
    component: Polls,
  },
  {
    path: '/schedule/',
    name: 'Schedule',
    component: Schedule,
  },
]

const router = createRouter({
  mode:'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
