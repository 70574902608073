<template>
  <div class="home">
    Home
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
export default {
  name: 'Home',
  data() {
    return {

    }
  },
  mounted(){
    document.title = 'Home'
  },
  methods: {
  }
}
</script>
