<template>
  <div class="schedule">
    <div class="box">
      <table class="table is-bordered">
        <thead>
          <tr>
            <th></th>
            <th>Monday</th>
            <th>Tuesday</th>
            <th>Wednesday</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>8:00</th>
            <td class="has-background-info"></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class ="box">
      <div id="top-labels">
        <div class="day">
          Mon
        </div>
        <div class="day">
          Tue
        </div>
        <div class="day">
          Wed
        </div>
      </div>
      <div id="left-labels">
        <div class="time">
          8:00
        </div>
        <div class="time">
          9:00
        </div>
        <div class="time">
          10:00
        </div>
      </div>
      <div id="slots">
        <div class="col">
          <div class="slot">
          </div>
          <div class="slot">
          </div>
          <div class="slot">
          </div>
        </div>
        <div class="col">
          <div class="slot">
          </div>
          <div class="slot">
          </div>
          <div class="slot">
          </div>
        </div>
        <div class="col">
          <div class="slot">
          </div>
          <div class="slot">
          </div>
          <div class="slot">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:"Schedule",
  data(){
    return {

    }
  }
}
</script>

<style scoped>
#top-labels{
  display:inline-block;
  margin-left:3rem;
}
.day{
  height: 1.5rem;
  width: 2rem;
  display:inline;
}
.time{
  height: 4rem;
  width: 1.5rem;
}
.slot{
  height: 1rem;
  width: 2rem;
  border-style:solid;
}
.col{
  display:inline-block;
}
</style>