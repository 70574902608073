<template>
    <div class="box columns is-clickable my-3" @click="picked">
        <div class="column">
            {{title}}
        </div>
        <div class="column">
            {{published}}
        </div>
    </div>
</template>

<script>

export default{
    name:'CreateChoiceBoardGameItem',
    props:{
        title:String,
        published:String,
        id:Number,
    },
    emits:['pick'],
    methods:{
        picked(){
            this.$emit('pick',this.id)
        }
    },
}
</script>

<style scoped>

</style>