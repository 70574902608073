<template>
    <div class="modal" :class="{'is-active': active}">
        <div class = "modal-background"  @click="$emit('close')"></div>
        <div class="modal-content">
            <div class="box" :class="{'wrapper-outer':tabActives[0]}">
                <div class="tabs is-centered is-toggle-rounded">
                    <ul>
                        <li :class="{'is-active':tabActives[0]}" @click="tabSwitch(0)"><a>Search</a></li>
                        <li :class="{'is-active':tabActives[1]}" @click="tabSwitch(1)"><a>ByID</a></li>
                        <li :class="{'is-active':tabActives[2]}" @click="tabSwitch(2)"><a>Manual</a></li>
                    </ul>
                </div>
                <div class="wrapper" v-show="tabActives[0]">
                    <div class="box">
                        <div class="field">
                            <label class="label">
                                Suggestion
                            </label>
                            <input class="input" type="text" v-model="suggestion">
                        </div>
                        <div class="field">
                            <button class="button" @click="bggSearch">Search</button>
                        </div>
                    </div>
                    <div class="box scrollable has-background-grey-dark" v-if="searchActive">
                        <create-choice-board-game-item
                        v-for="item in searchResponse.items.item"
                        :key="item.$.id"
                        :title="item.name[0].$.value"
                        :published="item.yearpublished[0].$.value"
                        :id="item.$.id"
                        @pick="onPick"
                        ></create-choice-board-game-item>
                    </div>
                </div>
                <div v-show="tabActives[1]">
                    <div class = "field">
                        <label class ="label"> Game ID</label>
                        <div class = "control">
                            <input class = "input" v-model="id">
                        </div>
                    </div>
                    <button class ="button" @click="onPick(id)">Submit</button>
                </div>
                <div v-show="tabActives[2]">
                    <div class = "columns is-multiline">
                        <div class = "column is-4">
                            <div class="field">
                                <label class="label"> Name</label>
                                <div class="control">
                                    <input class="input" v-model="game.title">
                                </div>
                            </div>
                        </div>
                        <div class = "column is-2">
                            <div class="field">
                                <label class="label"> ID</label>
                                <div class="control">
                                    <input class="input" v-model="game.id">
                                </div>
                            </div>
                        </div>
                        <div class = "column is-7">
                            <div class="columns">
                                <div class="column">
                                    <div class ="field">
                                        <label class="label"> Player min</label>
                                        <div class="control">
                                            <input class="input" v-model="game.minPlayers">
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class ="field">
                                        <label class = "label">Player Max</label>
                                        <div class="control">
                                            <input class="input" v-model="game.maxPlayers">
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class ="field">
                                        <label class="label"> Best</label>
                                        <div class="control">
                                            <input class="input" v-model="game.bestPlayers">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class = "column is-3">
                            <div class="field">
                                <label class="label"> Play Time (mins)</label>
                                <div class="control">
                                    <input class="input" v-model="game.playTime">
                                </div>
                            </div>
                        </div>
                        <div class = "column is-2">
                            <div class="field">
                                <label class="label"> Rating</label>
                                <div class="control">
                                    <input class="input" v-model="game.rating">
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="button" @click="create">Submit</button>
                </div>
            </div>
        </div>
        <button class="modal-close" @click="$emit('close')"></button>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import xml2js from 'xml2js'
import CreateChoiceBoardGameItem from './CreateChoiceBoardGameItem'
import { searchValidator, pickValidator } from '../bin/validators'

export default {
    name:'CreateChoiceBoardGameModal',
    components:{
        'create-choice-board-game-item':CreateChoiceBoardGameItem ,
    },
    props:{
        active:Boolean,
        choices:Object,
    },
    emits:['close','createChoice'],
    data(){
        return{
            suggestion:'',
            searchResponse:{},
            searchActive:false,
            tabActives:[true,false,false],
            bggAxios:{},
            id:null,
            game:{
                title:null,
                id:null,
                weight:null,
                minPlayers:null,
                maxPlayers:null,
                bestPlayers:null,
                playTime:null,
                rating:null,
            },
        }
    },
    mounted(){
        this.bggAxios = axios.create()
        delete this.bggAxios.defaults.headers.common['Authorization']
    },
    methods:{
        reset(){
            for(var i in this.game){
                this.game[i]= null
            }
            this.searchResponse = {}
            this.tabActives = [true,false,false]
            this.searchActive = false
            this.id = null
            this.suggestion = ''
        },
        bggSearch(){
            var parser = new xml2js.Parser({validator:searchValidator})
            var suggestionEncoded = encodeURIComponent(this.suggestion)
            this.bggAxios
            .get(`https://www.boardgamegeek.com/xmlapi2/search?query=${suggestionEncoded}&type=boardgame`)
            .then(response=>{
                return response.data
            })
            .then(responseData=>{
                return parser.parseStringPromise(responseData)
            })
            .then(parsed=>{
                if(parsed.items.$.total = 0){
                    toast({
                        message: 'No results',
                        type: 'is-danger',
                        dismissible: true,
                        position: 'center'
                    })
                    return
                }
                this.searchResponse = parsed
                this.searchActive = true
            })
            .catch(error=>{
                if(error.response){
                    if(error.response.status === 429)
                    toast({
                        message: 'Too many requests. Please wait and try again',
                        type: 'is-danger',
                        dismissible: true,
                        position: 'center'
                    })
                    console.log(error.response.status)
                    console.log(error.response.data)
                }else{
                    console.log(error)
                }
            })
        },
        tabSwitch(id){
            this.tabActives = this.tabActives.map(e=> false )
            this.tabActives[id] =true
        },
        create(){
            for(var i in this.game){
                if(i===null){
                    toast({
                        message: 'All fields are required',
                        type: 'is-danger',
                        dismissible: true,
                        position: 'center'
                    })
                    return
                }
            }
            this.$emit('createChoice',this.game)
            this.$emit('close')
            this.reset()
        },
        onPick(id){
            var errored = false
            var parser = new xml2js.Parser({validator:pickValidator})
            this.bggAxios
            .get(`https://www.boardgamegeek.com/xmlapi2/thing?id=${id}&type=boardgame&stats=1`)
            .then(response=>{
                return response.data
            })
            .then(responseData=>{
                return parser.parseStringPromise(responseData)
            })
            .then(parsed=>{
                this.game.id= parsed.items.item[0].$.id
                this.game.title =parsed.items.item[0].name[0].$.value
                this.game.minPlayers = parsed.items.item[0].minplayers[0].$.value
                this.game.maxPlayers = parsed.items.item[0].maxplayers[0].$.value
                this.game.playTime = parsed.items.item[0].playingtime[0].$.value
                this.game.rating = parsed.items.item[0].statistics[0].ratings[0].average[0].$.value
                this.game.weight = parsed.items.item[0].statistics[0].ratings[0].averageweight[0].$.value
                var best = 0
                var bestVotes = 0
                for(var i of parsed.items.item[0].poll){
                    if(i.$.name !=='suggested_numplayers'){
                        continue
                    }
                    for( var j of i.results){
                        if(parseInt(j.result[0].$.numvotes) > bestVotes){
                            best = parseInt(j.$.numplayers)
                            bestVotes = parseInt(j.result[0].$.numvotes)
                        }
                    }
                }
                this.game.bestPlayers = best
            })
            .catch(error=>{
                if(error.response && error.response.status === 429){
                    toast({
                        message: 'Too many requests. Please wait and try again',
                        type: 'is-danger',
                        dismissible: true,
                        position: 'center'
                    })
                    errored = true
                    return
                }
                console.log(error)
            })
            if(errored){
                return
            }
            this.tabSwitch(2)
        },
    }
}
</script>

<style scoped>
.scrollable{
    height:90%;
    overflow-y:auto;
}
.wrapper{
    height:75%;
}
.wrapper-outer{
    height:100%;
    overflow-y:hidden;
}
.normal{
    height:100%;
    overflow-y:auto;
}
</style>