import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'

import {library} from '@fortawesome/fontawesome-svg-core'
import {faThumbsUp as farThumbsUp, faThumbsDown as farThumbsDown, faCircle} from '@fortawesome/free-regular-svg-icons'
import {faThumbsUp as fasThumbsUp, faThumbsDown as fasThumbsDown, faAngleDown, faChevronDown} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

import ChoiceItem from './components/ChoiceItem'
import LocalLogInModal from './components/LocalLogInModal'


library.add(fasThumbsUp, farThumbsUp, farThumbsDown, fasThumbsDown, faCircle,faAngleDown, faChevronDown)

axios.defaults.baseURL = 'https://apischeduling.skepticrobot.tech'
//axios.defaults.baseURL = 'http://174.138.48.176:8000'

createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.component('font-awesome-layers', FontAwesomeLayers)
.component('font-awesome-layers-text', FontAwesomeLayersText)
.component('choice-item',ChoiceItem)
.component('local-log-in-modal',LocalLogInModal)
.use(store)
.use(router)
.mount('#app')
