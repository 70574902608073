<template>
    <div id="Polls">
        <div class ="box">
            <button class = 'button' @click="activateModal(0)"> Create New Poll</button>
        </div>
        <div class='polls' v-if="gotPolls">
            <div class='box is-clickable' 
            v-for="poll in polls"
            :key="poll.id"
            @click="reRoute(poll.id)">
                <div class='columns'>
                    <div class='column'>
                        {{poll.name}}
                    </div>
                </div>
            </div>
        </div>
        <create-poll-modal
        :active="this.modalActives[0]"
        @close="onClose"
        @reRoute="onReRoute"
        ></create-poll-modal>
    </div>
</template>

<script>
import axios from 'axios'

import CreatePollModal from "../components/CreatePollModal"

export default {
    name:'Polls',
    components:{
    'create-poll-modal':CreatePollModal,
    },
    data(){
        return{
            polls:[],
            gotPolls:false,
            modalActives: [false],
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            axios
            .get('api/v1/polls/')
            .then(response=>{
                this.polls = response.data
                this.gotPolls =true
            })
            .catch(error=>{
                console.log(error)
            })
        },
        reRoute(id){
            this.$router.push(`/polls/${id}`)
        },
        activateModal(index){
            this.modalActives[index]=true
        },
        onClose(){
            this.modalActives=this.modalActives.map(e => false)
        },
        onReRoute(path){
            this.$router.push(path)
        },
    },
}
</script>