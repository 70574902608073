<template>
  <div id="choice-item">
    <div class="box" :class="{'mb-1' : showExtra}">
      <div class="columns is-vcentered">
        <div class="column has-text-centered">
          <p class=""><strong>Title</strong></p>
          <a :href="getLink" target="_blank" rel="noopener noreferrer">{{ title }}</a>
        </div>
        <div class="column">
          <p class=""><strong>Score</strong></p>
            {{rating}}
        </div>
        <div class="column">
          <p class=""><strong>Weight</strong></p>
            {{weight}}
        </div>
        <div class="column">
          <p class=""><strong>Play Time</strong></p>
            {{play_time}}
        </div>
        <div class="column">
          <p class=""><strong>Players</strong></p>
            {{min_players}} - {{max_players}}
        </div>
        <div class="column">
          <p class=""><strong>Best</strong></p>
            {{best_players}}
        </div>
        <div class="column is-1">
          <span class="icon is-large is-clickable" @click="$emit('vote',id,1)">
            <font-awesome-icon :icon="['fas','thumbs-up']" v-if="votes[username] === 1"></font-awesome-icon>
            <font-awesome-icon :icon="['far','thumbs-up']" v-else ></font-awesome-icon>
          </span>
        </div>
        <div class="column is-1">
          <span class="icon is-large">
            <font-awesome-layers full-width class="fa-2x">
              <font-awesome-icon :icon="['far','circle']"></font-awesome-icon>
              <font-awesome-layers-text transform="shrink-6" :value="score" :key="score"></font-awesome-layers-text>
            </font-awesome-layers>
          </span>
        </div>
        <div class="column is-1">
          <span class="icon is-large is-clickable" @click="$emit('vote',id,-1)">
            <font-awesome-icon :icon="['fas','thumbs-down']" v-if="votes[username] === -1"></font-awesome-icon>
            <font-awesome-icon :icon="['far','thumbs-down']" v-else></font-awesome-icon>
          </span>
        </div>
      </div>
      <div class="is-clickable" @click="showExtra = !showExtra">
        <font-awesome-icon :icon="['fas','chevron-down']" class="is-pulled-right "></font-awesome-icon>
      </div>
    </div>
    <div class="box has-background-grey-light has-text-black mx-3" v-show="showExtra">
      <div class="columns ">
        <div class="column">
          <div class="content">
            <p>Created By </p>
            {{creatorTrimmed}}
          </div>
        </div>
        <div class="column mx-3">
          <div class="content">
            <p> Votes For </p>
            <ul>
              <li v-for="(value,index) in getVotesFor"
              :key="index">
                {{value}}
              </li>
            </ul>
          </div>
        </div>
        <div class="column mx-3">
          <div class="content">
            <p> Votes Against </p>
            <ul>
              <li v-for="(value,index) in getVotesAgainst"
              :key="index">
                {{value}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name:"ChoiceItem",
    props:{
        votes: Object,
        username: String,
        id:Number,
        title:String,
        score:Number,
        bgg_id:Number,
        weight:Number,
        rating:Number,
        play_time:Number,
        min_players:Number,
        max_players:Number,
        best_players:Number,
        creator:String,
    },
    emits: ['vote'],
    data(){
      return{
        showExtra:false,
      }
    },
    computed:{
        getLink(){
          return "https://boardgamegeek.com/boardgame/" + this.bgg_id
        },
        getVotesFor(){
          var votedFor = []
          for(var i in this.votes){
            if (this.votes[i] == 1){
              if(i.slice(i.length-2,i.length) == '_l'){
                votedFor.push(i.slice(0,i.length-2))
              }else{
                votedFor.push(i)
              }
            }
          }
          return votedFor
        },
        getVotesAgainst(){
          var votedAgainst = []
          for(var i in this.votes){
            if (this.votes[i] == -1){
              if(i.slice(i.length-2,i.length) == '_l'){
                votedAgainst.push(i.slice(0,i.length-2))
              }else{
                votedAgainst.push(i)
              }
            }
          }
          return votedAgainst
        },
        creatorTrimmed(){
          var l = this.creator.length
          if(this.creator.slice(l-2,l) == '_l'){
            return this.creator.slice(0,l-2)
          }else{
            return this.creator
          }
        }
    }
}
</script>

<style scoped>

</style>