// xml2js validators

export function searchValidator(xpath, currentValue, newValue){
    //replace missing values with defaults
    if(['/items/item'].includes(xpath)){
        if (!('name' in newValue)){
            newValue.name = [ { $:{ value:'' } } ]
        }
        if (!('yearpublished' in newValue)){
            newValue.yearpublished = [ { $:{ value:'' } } ]
        }
        //convert strings to numbers
        newValue.$.id = parseInt(newValue.$.id)
        return newValue
    }else if(xpath == '/validationerror'){
        throw new xml2js.ValidationError("Validation error!")
    }
    return newValue
}

export function pickValidator(xpath, currentValue, newValue){
    //replace missing values with defaults
    if(['/items/item'].includes(xpath)){
        if(!('name' in newValue)){
            newValue.name = [ { $:{ value:'' } } ]
        }
        if(!('minplayers' in newValue)){
            newValue.minplayers = [ { $:{ value:0 } } ]
        }
        if(!('maxplayers' in newValue)){
            newValue.maxplayers = [ { $:{ value:0 } } ]
        }
        if(!('playtime' in newValue)){
            newValue.playtime = [ { $:{ value:0 } } ]
        }
        if(!('statistics' in newValue)){
            newValue.statistics = [ { ratings:[ {
                average: [ { $:{ value:0 } } ],
                averageweight: [ { $:{ value:0 } } ]
            }]} ]
        }
        if(!('poll' in newValue)){
            newValue.poll = [ { $:{name:''}}]
        }
        return newValue
    }
    //convert strings to numbers
    else if([
        '/items/item/minplayers',
        '/items/item/maxplayers',
        '/items/item/playtime',
        '/items/item/statistics/ratings/average',
        '/items/item/statistics/ratings/averageweight',]
        .includes(xpath)){
            newValue.$.value = parseInt(newValue.$.value)
            return newValue
    }
    else if(['/items/item/poll',].includes(xpath)){
        if(newValue.$.name ==='suggested_numplayers'){
            for(var i of newValue.results){
                i.$.numplayers = parseInt(i.$.numplayers)
                for(var j of i.result){
                    j.$.numvotes= parseInt(j.$.numvotes)
                }
            }
            console.log(newValue)
        }
        return newValue
    }
    else if(xpath == '/validationerror'){
        throw new xml2js.ValidationError("Validation error!")
    }
    return newValue
}
