<template>
    <div class="modal" :class="{'is-active': active}">
        <div class = "modal-background"  @click="$emit('close')"></div>
        <div class="modal-content">
            <div class="box">
                <div class="field">
                    <label class="label">
                        Poll Name
                    </label>
                    <input class="input" type="text" v-model="suggestion">
                </div>
                <div class="field">
                    <div class="control">
                        <div class="select">
                            <select v-model="selected">
                                <option disabled value="">Select Type</option>
                                <option value="N">Normal</option>
                                <option value="BG">Boardgame</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="field">
                    <button class="button" @click="createPoll">Submit</button>
                </div>
            </div>
        </div>
        <button class="modal-close" @click="$emit('close')"></button>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
    name:'CreatePollModal',
    props:{
        active:Boolean,
    },
    emits:['reRoute','close'],
    data() {
        return{
            suggestion:'',
            activeDrop:false,
            selected:'',
        }
    },
    methods:{
        createPoll(){
            if(this.suggestion===''||this.selected===''){
                toast({
                        message: 'A poll must have a name and a type',
                        type: 'is-danger',
                        dismissible: true,
                        position: 'center'
                    })
                    return
            }
            const pollData = {
                name:this.suggestion,
                type:this.selected
            }
            axios
            .post(`api/v1/polls/`,pollData)
            .then(response=>{
                const toPath = `/polls/${response.data.id}/`
                this.$emit('reRoute',{path:toPath})
                this.$emit('close')
            })
            .catch(error=>{
                if(error.response){
                    console.log(error.response.data)
                    console.log(error.response.headers)
                }
            })
        }
    }
}
</script>