<template>
  <div id="wrapper">
    <nav class ="navbar is-dark">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item"><strong>Scheduling</strong></router-link>
        <a class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbar-menu"  @click="showMobile = !showMobile">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          </a>
        </div>
      <div class="navbar-menu" id="navbar-menu" :class="{'is-active': showMobile}">
        <div class="navbar-end">
          <router-link to="/polls" class="navbar-item" @click="showMobile = false">Polls</router-link>
          <!--<router-link to="/events" class="navbar-item">Events</router-link>-->

          <div class="navbar-item">
            <div class="buttons">
              <template v-if="$store.state.isAuthenticated">
                <router-link to="/my-account" class="button is-light" @click="showMobile = false">My account</router-link>
              </template>

              <template v-else>
                <router-link to="/log-in" class="button is-light" @click="showMobile = false">Log in</router-link>
              </template>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <section class="section">
      <router-view/>
    </section>

    <footer class="footer">
    </footer>
  </div>
</template>

<script type="text/javascript">
  import axios from 'axios'

  export default{
    data(){
      return{
        showMobile:false,
      }
    },
    beforeCreate(){
      this.$store.commit('initializeStore')

      const token = this.$store.state.token

      if(token){
        axios.defaults.headers.common['Authorization'] = "Token " + token
      } else{
        axios.defaults.headers.common['Authorization'] = ""
      }
    },
  }
</script>
<style lang="scss">
@import '../node_modules/bulma';
</style>
