<template>
  <div class="page-poll">
    <div class="column is-8 is-offset-2">
      <div class="box">
        <p class="has-text-centered is-size-1"><strong>{{poll.name}}</strong></p>
      </div>
    </div>
    <div class="column is-8 is-offset-2">
      <component
      :is="choiceComponent"
      v-for="(choice,ind) in sortedChoices"
      :key="ind"
      :="choice"
      :username="this.username"
      @vote="onVote"
      ></component>
    </div>
    <div class="column is-8 is-offset-2">
      <div class="box">
        <div class="columns is-centered is-vcentered">
          <div class="column is-1"></div>
          <div class="column">
            <button class="button" @click="activateModal(1)">Add Choice</button>
          </div>
          <div class="column">
            <button class="button" @click="activateModal(2)">Create New Poll</button>
          </div>
          <div class="column is-narrow">
            <button class="button" v-if="username==''" @click="activateModal(0)">Local Log In</button>
            <p v-else class="is-size-3"> <strong>User:</strong> {{trimmedUsername}} </p>
          </div>
          <div class="column is-1"></div>
        </div>
      </div>
    </div>

    <local-log-in-modal 
    :localUsers="this.poll['local_users']" 
    :active="this.modalActives[0]"
    @localLogIn="onLogInLocalUser"
    @createLocalUser="onCreateLocalUser"
    @close="onClose"
    ></local-log-in-modal>
    <component
    :is="createChoiceComponent"
    :choices="choices"
    :active="this.modalActives[1]"
    @close="onClose"
    @createChoice="onCreateChoice"
    ></component>
    <create-poll-modal
    :active="this.modalActives[2]"
    @close="onClose"
    @reRoute="onReRoute"
    ></create-poll-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

import CreateChoiceModal from "../components/CreateChoiceModal"
import CreateChoiceBoardGameModal from "../components/CreateChoiceBoardGameModal"
import CreatePollModal from "../components/CreatePollModal"
import ChoiceItemBoardGame from "../components/ChoiceItemBoardGame"

export default {
  name: 'Poll',
  components:{
    'create-choice-modal':CreateChoiceModal,
    'create-choice-board-game-modal':CreateChoiceBoardGameModal,
    'create-poll-modal':CreatePollModal,
    'choice-item-board-game':ChoiceItemBoardGame,
  },
  data() {
    return {
      poll: {},
      choices: {},
      username: '',
      modalActives:[false,false,false],
      type:'N'
    }
  },
  computed:{
    createChoiceComponent(){
      if(this.type ==='N'){
        return 'create-choice-modal'
      }else{
        return 'create-choice-board-game-modal'
      }
    },
    choiceComponent(){
      if(this.type ==='N'){
        return 'choice-item'
      }else{
        return 'choice-item-board-game'
      }
    },
    sortedChoices(){
      var sorted = Object.values(this.choices)
      return sorted.sort((a,b)=>{
        return b.score-a.score
      })
    },
    trimmedUsername(){
      var l = this.username.length
      if(this.username.slice(l-2,l) == '_l'){
        return this.username.slice(0,l-2)
      }else{
        return this.username
      }
    },
  },
  mounted(){
    this.getData()
    this.getUser()
  },
  watch:{
    '$route':'initialize'
  },
  methods: {
    getPoll(){
      const poll_slug = this.$route.params.poll_slug

      axios
        .get(`/api/v1/polls/${poll_slug}`)
        .then(response =>{
          this.poll = response.data
          document.title = this.poll.name
        })
        .catch(error =>{
          console.log(error)
        })
    },
    getChoices(){
      var temp = []
      for (i in this.poll.choices){
        temp.push(axios.get(`/api/v1/choices/${i}`))
      }
      Promise.all(temp).then(function(results){
        return results
      })
      .catch(function(error){
        console.log(error)
      })
    },
    getData(){
      this.choices = {}
      const poll_slug = this.$route.params.poll_slug
      axios
      .get(`/api/v1/polls/${poll_slug}`)
      .then(response =>{
        this.poll = response.data
        this.type = this.poll.type
        var temp = []
        if(this.type ==='N'){
          for(var i in response.data.choices){
            temp.push(axios.get(`/api/v1/choices/${response.data.choices[i]}`))
          }
        }else{
          for(var i in response.data.choices){
            temp.push(axios.get(`/api/v1/choicesBoardGame/${response.data.choices[i]}`))
          }
        }
        Promise.all(temp).then(results =>{
          for(var k in results){
            this.choices[results[k].data.id] = results[k].data
          }
        }).catch(error =>{
          console.log(error)
        })
      })
    },
    getUser(){
      if(this.$store.state.isAuthenticated){
        axios
        .get(`/api/v1/users/me/`)
        .then(response =>{
          this.username = response.data.username
        })
        .catch(error =>{
          console.log(error)
        })
      }else{
        this.username = ''
      }
    },
    initialize(){
      if(this.$route.params.poll_slug){
        this.getUser()
        this.getData()
      }
    },
    activateModal(index){
      if(index == 1 && this.username == '' ){
        index = 0
        toast({
          message: 'Please log in first',
          type: 'is-danger',
          dismissible: true,
          position: 'center'
        })
      }
      this.modalActives[index]=true
    },
    onVote(choice_id,vote){
      if(this.username === ''){
        this.modalActives[0] = true
        return
      }
      var url
      if(this.type ==='N'){
        url = `/api/v1/choices/${choice_id}/vote/`
      }else{
        url = `/api/v1/choicesBoardGame/${choice_id}/vote/`
      }
      var old_vote = this.choices[choice_id].votes[this.username]
      if(old_vote === vote){
        delete this.choices[choice_id].votes[this.username]
        this.choices[choice_id].score += -1*vote
      }else if(old_vote){
        this.choices[choice_id].votes[this.username] = vote
        this.choices[choice_id].score += vote - old_vote
      }else{
        this.choices[choice_id].votes[this.username] = vote
        this.choices[choice_id].score += vote
      }
      const patchData ={
        'vote':vote,
        'username':this.username,
        'old_vote':old_vote,
      }
      axios
      .patch(url, patchData)
      .catch(error=>{
        console.log(error)
      })
    },
    onLogInLocalUser(username_l,password){
      this.username=username_l
    },
    onCreateLocalUser(username_l,password){
      this.username=username_l
      this.poll['local_users'][username_l] = password
      const patchData={
        username:this.username,
        password:password
      }
      axios
      .patch(`api/v1/polls/${this.poll.id}/add_local_user/`,patchData)
      .catch(error=>{
        console.log(error)
      })
    },
    onClose(){
      this.modalActives=this.modalActives.map(e => false)
    },
    onCreateChoice(suggestion){
      if(this.type === 'N'){
        const choiceData={
          title:suggestion,
          poll:this.poll.id
        }
        axios
        .post(`api/v1/choices/`,choiceData)
        .then(response=>{
          this.choices[response.data.id] = response.data
        })
        .catch(error=>{
          console.log(error)
        })
      }else{
        const choiceData={
          poll:this.poll.id,
          title:suggestion.title,
          bgg_id:suggestion.id,
          weight:suggestion.weight,
          rating:suggestion.rating,
          play_time:suggestion.playTime,
          min_players:suggestion.minPlayers,
          max_players:suggestion.maxPlayers,
          best_players:suggestion.bestPlayers,
          creator:this.username,
        }
        axios
        .post(`api/v1/choicesBoardGame/`,choiceData)
        .then(response=>{
          this.choices[response.data.id] = response.data
        })
        .catch(error=>{
          console.log(error)
        })
      }
      
    },
    onReRoute(path){
      this.$router.push(path)
      //this.getData()
    },
  }
}
</script>
