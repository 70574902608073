<template>
    <div class="modal" :class="{'is-active': active}">
        <div class = "modal-background"  @click="$emit('close')"></div>
        <div class="modal-content">
            <div class="box">
                <div class="field">
                    <label class="label">
                        Suggestion
                    </label>
                    <input class="input" type="text" v-model="suggestion">
                </div>
                <div class="field">
                    <button class="button" @click="createChoice">Submit</button>
                </div>
            </div>
        </div>
        <button class="modal-close" @click="$emit('close')"></button>
    </div>
</template>

<script>
import { toast } from 'bulma-toast'

export default {
    name:'CreateChoiceModal',
    props:{
        active:Boolean,
        choices:Object,
    },
    emits:['close','createChoice'],
    data(){
        return{
            suggestion:'',
        }
    },
    methods:{
        createChoice(){
            for(const obj in this.choices){
                var c = this.choices[obj]
                if(c.name === this.suggestion){
                    toast({
                        message: 'That is already an option',
                        type: 'is-danger',
                        dismissible: true,
                        position: 'center'
                    })
                    return
                }
            }
            this.$emit('close')
            this.$emit('createChoice',this.suggestion)
            this.suggestion = ''
        }
    }
}
</script>