<template>
    <div class="box">
        <div class="columns is-mobile">
            <div class="column has-text-centered">
              {{ title }}
            </div>
            <div class="column is-1 is-clickable">
              <span class="icon is-large" @click="$emit('vote',id,1)">
                <font-awesome-icon :icon="['fas','thumbs-up']" v-if="votes[username] === 1"></font-awesome-icon>
                <font-awesome-icon :icon="['far','thumbs-up']" v-else ></font-awesome-icon>
              </span>
            </div>
            <div class="column is-2 is-offset-1">
              <span class="icon is-large">
                <font-awesome-layers full-width class="fa-2x">
                  <font-awesome-icon :icon="['far','circle']"></font-awesome-icon>
                  <font-awesome-layers-text transform="shrink-6" :value="score" :key="score"></font-awesome-layers-text>
                </font-awesome-layers>
              </span>
            </div>
            <div class="column is-1 is-clickable">
              <span class="icon is-large" @click="$emit('vote',id,-1)">
                <font-awesome-icon :icon="['fas','thumbs-down']" v-if="votes[username] === -1"></font-awesome-icon>
                <font-awesome-icon :icon="['far','thumbs-down']" v-else></font-awesome-icon>
              </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"ChoiceItem",
    props:{
        votes: Object,
        username: String,
        id:Number,
        title:String,
        score:Number,
        creator:String,
    },
    emits: ['vote'],
    computed:{
        getScore(){
            var s = 0
            for(var i in this.votes){
                s += this.votes[i]
            }
            return s
        }
    }
}
</script>